<template>
  <el-container class="container">
    <el-main class="flex flex1 flex-wrap main">
      <DigitalProductCard v-for="product in products" :key="product.id" :product="product" />
    </el-main>
    <!-- 右侧详情部分可以根据需要定制，这里仅作示意 -->
    <el-aside class="aside" width="372px">
      <!-- 商品详情展示，例如通过点击卡片上的查看更多按钮来切换显示的内容 -->
      <div v-if="selectedProduct" class="product-details">
        <h3>{{ selectedProduct.name }}</h3>
        <p>版本号: {{ selectedProduct.version }}</p>
        <p>简介: {{ selectedProduct.short_description }}</p>
        <a :href="selectedProduct.long_description_link" target="_blank">查看完整简介</a>
        <a :href="selectedProduct.download_link" download>下载软件</a>
      </div>
      <!-- 其他功能按钮等 -->
    </el-aside>
  </el-container>
</template>

<script>
import DigitalProductCard from "@/views/data/productSpace/components/DigitalProductCard.vue"; // 替换为实际路径

export default {
  components: {
    DigitalProductCard,
  },
  data() {
    return {
      products: [], // 从后端API或其他数据源获取商品数据
      selectedProduct: null, // 当前选中的商品，用于在右侧详情展示
    };
  },
  mounted() {
    this.fetchProducts(); // 加载商品数据
  },
  methods: {
    fetchProducts() {
      // 假设有一个API接口返回商品数据，你可以使用axios或其他HTTP库进行请求
      // axios.get('/api/products').then(response => {
      //   this.products = response.data;
      // });
      // 这里为了示例，使用静态数据代替
      this.products = [
        {
          id: 1,
          name: '软件1',
          version: '1.0.0',
          short_description: '这是软件1的简介',
          long_description_link: 'http://example.com/software1',
          download_link: 'http://example.com/software1.zip',
          // 其他字段...
        },
        // 更多商品数据...
      ];
    },
    // 添加一个方法来处理商品的选择，例如点击卡片上的查看更多时触发
    selectProduct(product) {
      this.selectedProduct = product;
    },
  },
};
</script>

<style scoped>
/* 根据需要添加样式 */
</style>
