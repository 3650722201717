<template>
  <div style="height: 100%;">
    <el-row :gutter="20" class="container">
      <!-- 右边主要区 -->
      <el-col :span="24" :xs="24" class="container" style="flex-direction: column;border-width: 0;padding-top: 25px;">
        <!-- 右边头部搜索区 -->
        <el-form :model="queryParams.param" ref="queryForm" :inline="true" label-position="right" v-show="showSearch"
                 label-width="110px">
          <!-- 名称 -->
          <el-form-item :label="columnInfo.name.label" prop="name">
            <el-input v-model="queryParams.param.name" placeholder="请输入内容" size="small" clearable></el-input>
          </el-form-item>
          <!-- 资源路径 -->
          <el-form-item :label="columnInfo.url.label" prop="url">
            <el-input v-model="queryParams.param.url" placeholder="请输入内容" size="small" clearable></el-input>
          </el-form-item>
          <!-- 账号 -->
          <el-form-item :label="columnInfo.account.label" prop="account">
            <el-input v-model="queryParams.param.account" placeholder="请输入内容" size="small" clearable></el-input>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item :label="columnInfo.password.label" prop="password">
            <el-input v-model="queryParams.param.password" placeholder="请输入内容" size="small" clearable></el-input>
          </el-form-item>
          <!-- 类型 -->
          <el-form-item :label="columnInfo.typeId.label" prop="typeId">
            <options-select v-model="queryParams.param.typeId" :options="options.typeOptions" placeholder="请选择"
                            style="width: 100%;" size="small" clearable></options-select>
          </el-form-item>
          <!-- 序号 -->
          <el-form-item :label="columnInfo.sort.label" prop="sort">
            <el-input v-model="queryParams.param.sort" placeholder="请输入内容" size="small" clearable></el-input>
          </el-form-item>
          <!-- 状态 -->
          <el-form-item :label="columnInfo.state.label" prop="state">
            <enum-dict-select v-model="queryParams.param.state" module-name="data" dict-code="state"  placeholder="请选择" size="small" clearable/>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="small" @click="handleTableSearch">搜索</el-button>
            <el-button icon="el-icon-refresh" size="small" @click="clickReset">重置</el-button>
          </el-form-item>
        </el-form>
        <!-- 右边中部功能区 -->
        <el-row :gutter="10" style="margin-bottom:13px;margin-right: 1px">
          <el-col :span="1.5">
            <el-button
                type="primary"
                plain
                icon="el-icon-plus"
                size="small"
                @click="clickAdd"
                v-permissionDisabled="'data:source:create'"
            >新增
            </el-button>
          </el-col>
          <el-col :span="1.5">
            <excel-import-plus title="数据源表" module-name="data" table-name="source"
                               :permission-code="'data:source:import'"></excel-import-plus>
          </el-col>
          <el-col :span="1.5">
            <excel-export-plus file-name="数据源表" module-name="data" table-name="source" :select-list="selectList"
                               :permission-code="'data:source:export'"></excel-export-plus>
          </el-col>
          <el-col :span="1.5">
            <el-button
                type="success"
                plain
                icon="el-icon-s-operation"
                size="small"
                :loading="exportLoading"
                @click="toSourceType"
            >数据源类型
            </el-button>
          </el-col>
          <right-toolbar-plus :showSearch.sync="showSearch" @queryTable="handleTableSearch"
                              :columnInfo="columnInfo" @redoTableLayout="redoTableLayout"></right-toolbar-plus>
        </el-row>
        <!-- 右边中部表格区 -->
        <div class="main">
          <div class="table">
            <el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                      @selection-change="handleSelectionChange" height="100%"
                      :header-cell-style="{background:'#ECF5FF'}">
              <el-table-column align="center" type="selection" width="55"/>
              <!-- 名称 -->
              <el-table-column prop="name" :label="columnInfo.name.label"
                               v-if="columnInfo.name.visible"></el-table-column>
              <!-- 资源路径 -->
              <el-table-column prop="url" :label="columnInfo.url.label"
                               v-if="columnInfo.url.visible" show-overflow-tooltip width="500">
                <template v-slot="scope">
                  <el-link type="primary" @click="linkDataBase(scope.row)">{{ scope.row.url }}</el-link>
                </template>
              </el-table-column>
              <!-- 账号 -->
              <af-table-column prop="account" :label="columnInfo.account.label"
                               v-if="columnInfo.account.visible"></af-table-column>
              <!-- 密码 -->
              <el-table-column prop="password" :label="columnInfo.password.label"
                               v-if="columnInfo.password.visible"></el-table-column>
              <!-- 类型 -->
              <el-table-column prop="typeId" :label="columnInfo.typeId.label"
                               v-if="columnInfo.typeId.visible">
                <template v-slot="scope">
                  <option-name-label v-model="scope.row.typeId" :options="options.typeOptions"
                                     field-name="name"></option-name-label>
                </template>
              </el-table-column>
              <!-- 序号 -->
              <el-table-column prop="sort" :label="columnInfo.sort.label"
                               v-if="columnInfo.sort.visible"></el-table-column>
              <!-- 状态 -->
              <el-table-column prop="state" :label="columnInfo.state.label"
                               v-if="columnInfo.state.visible">
                <template v-slot="scope">
                  <enum-dict-label v-model="scope.row.state" dict-code="state" module-name="data"
                                   show-tag></enum-dict-label>
                </template>
              </el-table-column>
              <!-- 操作列 -->
              <el-table-column align="center" label="操作" width="355" fixed="right">
                <template v-slot="scope">
                  <el-button @click="clickEdit(scope.row)" type="primary" size="mini"
                             v-permissionDisabled="'data:source:update'">编辑
                  </el-button>
                  <el-button @click="clickConnect(scope.row)" type="success" size="mini">测试连接</el-button>
                  <el-button @click="clickDel(scope.row)" type="danger" size="mini"
                             v-permissionDisabled="'data:source:remove'">删除
                  </el-button>
                  <table-more-button :row="scope.row" :button-props="moreButtonProps"
                                     @handleCommand="handleCommand"></table-more-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 右边底部分页区 -->
        <div style="height: 40px;margin-bottom: 0.1rem;">
          <pagination-plus :totalCount="totalCount"
                           :param="queryParams"
                           :pageSizes="pageSizes"
                           :selectList="selectList"
                           module-name="data"
                           table-name="source"
                           :del-all-permission-code="'data:source:removes'"
                           :state-permission-code="'data:source:state'"
                           @handleTableRefresh="handleTableSearch"/>
        </div>
      </el-col>
    </el-row>
    <!-- 弹出层组件 -->
    <SourceForm/>
  </div>
</template>

<script>
/**
 * 数据源表主页面 （与自定义组件之间请遵循MVVM架构）
 * @author PeterLee
 * @since 2022-07-15
 */
import SourceForm from "./SourceForm"
import {connect} from "@/api/dataApi"
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  components: {
    // 表单弹出组件
    SourceForm
  },
  data() {
    return {
      // 每页条数选项（分页控件）
      pageSizes: [10, 20, 40, 80],
      // 批量操作选中list（分页控件）
      selectList: [],
      // 展示搜索区（right-toolbar）
      showSearch: false,
      // 导出loading
      exportLoading: false,
    }
  },
  computed: {
    ...mapState({
      // 表格数据
      tableData: state => state.data.source.tableData,
      // 下拉选项
      options: state => state.data.source.options,
      // 共有数据条数（分页控件）
      totalCount: state => state.data.source.totalCount,
      // 列信息
      columnInfo: state => state.data.source.columnInfo,
      // 查询参数
      queryParams: state => state.data.source.queryParams,
      // 时间范围
      dateRange: state => state.data.source.dateRange,
      // 更多按钮信息
      moreButtonProps: state => state.data.source.moreButtonProps,
    })
  },
  // 生命周期create函数
  created() {
    // 创建时获取所有权限数据（分页查询）
    this.handleTableSearch();
    // 获取OptionList
    this.getOptionList();
    // const userAgent = navigator.userAgent.toLowerCase();
    // // 浏览器运行提示
    // if (userAgent.indexOf('electron/') === -1){
    //   this.$message.warning("注意：为确保正常使用\"数字开发工具\"，请使用electron运行!")
    // }
  },
  methods: {
    ...mapMutations({
      clickEdit: 'data/source/clickEdit',
      clickAdd: 'data/source/clickAdd',
    }),
    ...mapActions({
      clickDel: 'data/source/handleDel',
      handleTableSearch: 'data/source/handleTableSearch',
      getOptionList: 'data/source/getOptionList',
    }),
    /** 重置按钮点击（搜索区） */
    clickReset() {
      for (let key of Object.keys(this.dateRange)) {
        this.dateRange[key] = []
      }
      this.$refs['queryForm'].resetFields();
      this.handleTableSearch();
    },
    /** 处理选择改变（表格区） */
    handleSelectionChange(selectList) {
      this.selectList = selectList;
    },
    /** 重新计算表格（按钮区） */
    redoTableLayout() {
      // 数据加载完成后执行
      this.$nextTick(() => {
        // 表格重新渲染
        this.$refs.multipleTable.doLayout();
      });
    },
    /** 更多按钮方法处理（按钮区） */
    handleCommand(command, row) {
      // console.log(command)
      // console.log(row)
    },
    /** 进入数据源类型 */
    toSourceType() {
      this.$router.push('sourceType')
    },
    /** 测试连接 */
    clickConnect(row) {
      connect(row.id).then(res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '测试连接成功！'
          });
        }
      })
    },
    // 跳转到数据库页面
    linkDataBase(row) {
      this.$router.push({name: 'DataBase/:id', params: {"id": row.id}})
    },
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/style/index.less');

::v-deep .el-form-item {
  margin-bottom: 18px;
}
</style>
