<template>
  <div style="display: inline-block;margin: 0 8px;">
    <!-- 外置按钮 -->
    <el-button @click="clickButton" type="success" plain style="width: 100%;">
      <slot></slot>
    </el-button>
    <!-- 点击弹出框 -->
    <el-dialog :visible.sync="dialogVisible" width="1020px" @close="clickDialogClose" center
               append-to-body top="30px" style="">
      <div style="padding: 0 45px;">
        <div style="text-align: center;width: 100%;font-weight: bolder;font-size: large;">智能建表</div>
        <!-- 大模型输入区 -->
        <div class="flex-ac" style="margin-top: 20px;">
          <el-input placeholder="请输入生成表的prompt" v-model="inputText"></el-input>
          <div style="width: 2px;"></div>
          <el-button icon="el-icon-s-promotion" @click="clickSubmit">提交</el-button>
        </div>
        <!-- 大模型内容输出区 -->
        <textarea style="width: 100%;margin-top: 15px;height: 300px;" v-model="sqlText" placeholder="  建表语句文件内容">

        </textarea>
        <!-- 目标文件名 -->
        <el-input style="margin-top: 15px;" placeholder="目标文件名" v-model="sqlFileName"></el-input>
        <!-- 文件生成路径及文件名称 -->
        <directory-select v-model="path" style="margin-top: 15px;" placeholder="目标文件生成路径"></directory-select>
      </div>
      <!-- 底部按钮区 -->
      <template #footer class="dialog-footer">
        <el-button @click="clickCreate" type="success" size="medium">生成</el-button>
        <el-button @click="clickCancel" size="medium">取 消</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>

import baseUrl from "@/config/baseUrl";

/**
 * 组织关联按钮
 *
 * @author PeterLee
 * @date 2022-07-28
 */
export default {
  name: 'CodePreviewButton',
  components: {

  },
  // 父组件传入参数
  props: {
    tableId: String,
  },
  // 计算操作
  computed: {},
  data() {
    return {
      // 组件内部弹出框展示与否
      dialogVisible: false,
      previewCodes:[],
      inputText:"",
      path:"",
      sqlText:"",
      sqlFileName:""
    }
  },
  methods: {
    // 点击提交
    clickSubmit() {
      // 清空已有文字
      this.sqlText = '';
      this.createSqlFileName();
      // 只有当eventSource不存在时才创建新的EventSource连接
      if (!this.eventSource) {
        // 创建新的EventSource连接
        //this.eventSource = new EventSource('http://127.0.0.1:8080/completions?messages='+this.inputText);
        this.eventSource = new EventSource(baseUrl+'data/llm/sql?prompt='+this.inputText);

        // 设置消息接收的回调函数
        this.eventSource.onmessage = (event) => {
          const data = JSON.parse(event.data);
          this.sqlText += data.result;
        };

        // 可选：监听错误事件，以便在出现问题时能够重新连接或处理错误
        this.eventSource.onerror = (event) => {
          console.error("EventSource failed:", event);
          this.eventSource.close(); // 关闭出错的连接
          this.eventSource = null; // 重置eventSource变量，允许重建连接
        };
      }
    },
    createSqlFileName() {
      // 创建一个Date对象表示当前日期和时间
      const now = new Date();

      // 获取年、月、日、时、分
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，所以需要+1，并补零
      const day = now.getDate().toString().padStart(2, '0'); // 补零
      const hours = now.getHours().toString().padStart(2, '0'); // 补零
      const minutes = now.getMinutes().toString().padStart(2, '0'); // 补零

      // 使用模板字符串格式化文件名
      this.sqlFileName = `V${year}.${month}.${day}.${hours}.${minutes}__create_data_source.sql`;
      this.path = "C:\\Users\\v_lihaocai\\IdeaProjects\\redid\\process-domain\\data\\src\\main\\resources\\db\\migration"
    },
    // 点击按钮
    clickButton() {
      this.$nextTick(() => {
        // 获取代码内容
        // this.previewCodes = res.data;
        this.dialogVisible = true;
      })
    },
    // 点击取消按钮
    clickCancel() {
      this.dialogVisible = false;
    },
    // 关闭弹窗时
    clickDialogClose() {
      this.dialogVisible = false;
    },
    // 生成
    clickCreate() {
      //浏览器运行提示(非electron运行)
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf('electron/') === -1) {
        this.$message.error("提示：浏览器无法生成代码到对应目录，请下载客户端后使用该功能！")
        return;
      }
      this.$confirm('此操作目标文件生成到目标路径, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const data = {
          file: this.path + '\\' + this.sqlFileName,
          path: this.path,
          data: this.sqlText
        }
        const isSuccess = window.ipcRenderer.sendSync('asynchronous-message',JSON.stringify(data));
        this.dialogVisible = !isSuccess;
        if (isSuccess) {
          this.$message({
            type: 'success',
            message: '生成成功!'
          });
        } else {
          this.$message({
            type: 'warning',
            message: '生成失败!'
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消生成'
        });
      });
    }
  },
  created() {

  },
  // mounted生命周期
  mounted() {

  },
  // 生命周期updated执行时
  updated() {

  }
}
</script>

<style lang="less" scoped>
/* tree border */
.tree-border {
  margin-top: 5px;
  border: 1px solid #e5e6e7;
  background: #FFFFFF none;
  border-radius: 4px;
}
::v-deep .el-dialog__body {
  padding-bottom: 0;
}
::v-deep .el-dialog__footer {
  padding: 0 20px 30px;
  font-size: 12px;
}

::v-deep .el-tabs {
  border-radius: 6px;
}

::v-deep .el-dialog {
  border-radius: 6px;
}

::v-deep .el-dialog__title {
  font-weight: bold;
  font-size: 0.4rem;
}

::v-deep .el-dialog__close {

}

::v-deep .el-dialog__body {
  padding: 20px;
}

::v-deep .el-dialog__header {
  //padding: 16px 0 0;
  height: 22px;
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
  align-items: center;
}

::v-deep .el-dialog__headerbtn {

}

::v-deep .el-dialog__headerbtn i {
  font-size: 0.4rem;
}


.dialog-footer {
  padding: 0;
}
</style>
