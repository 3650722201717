<template>
  <div style="display: inline;margin: 0 8px;">
    <el-button @click="clickCodeConfirmButton" size="medium" type="success">
      <slot></slot>
    </el-button>
    <el-dialog :visible.sync="dialogVisible" width="355px" @close="clickDialogClose" center
               append-to-body top="188px">
      <div style="margin: 0 26px;">
        <div style="font-size: 20px;color: black;">请选择需要生成的代码：</div>
        <div style="font-size: 12px;color: gray;">温馨提示：生成代码前请确认文件路径是否正确</div>
        <!-- 生成代码选择区 -->
        <div style="max-height: 5rem;overflow-y: auto;margin-top: 0.25rem;">
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          <el-checkbox-group v-model="checkedIds" @change="handleCheckedChange"
                             style="display: flex;flex-direction: column;margin-top: 5px;">
            <el-checkbox v-for="(item,index) in previewCodes" :label="item.key.split('/').pop()" :key="index">
              {{ item.key.split('/').pop() }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div style="width:100%; margin-top: 32px;display: flex;flex-direction: row; justify-content: center;">
          <el-button type="primary" @click="clickConfirm" size="small">确 定</el-button>
          <el-button @click="clickCancel" size="small">取 消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {Message} from 'element-ui'
/**
 * 组织关联按钮
 *
 * @author PeterLee
 * @date 2022-08-08
 */
export default {
  name: 'CodeConfirmButton',
  // 父组件传入参数
  props: {
    previewCodes: {
      type: Array,
      default:() =>{
        return []
      }
    }
  },
  // 计算操作
  computed: {},
  data() {
    return {
      // 组件内部弹出框展示与否
      dialogVisible: false,
      checkedIds:[],
      isIndeterminate: false,
      checkAll: false,
    }
  },
  methods: {
    // 点击数据权限按钮
    clickCodeConfirmButton() {
      this.dialogVisible = true;
    },
    // 点击取消按钮
    clickCancel() {
      this.dialogVisible = false;
    },
    // 关闭弹窗时
    clickDialogClose() {
      this.dialogVisible = false;
    },
    // 点击全选
    handleCheckAllChange(val) {
      this.checkedIds = val ? this.previewCodes.map(item=> item.key.split('/').pop()) : [];
      this.isIndeterminate = false;
    },
    // 确认需要生成的代码
    clickConfirm() {
      //浏览器运行提示(非electron运行)
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf('electron/') === -1) {
         Message.error("提示：浏览器无法生成代码到对应目录，请下载客户端后使用该功能！")
         return;
      }
      this.previewCodes.forEach( item => {
        const fileName = item.key.split('/').pop();
        const filePath = item.key.substring(0,item.key.length-(fileName).length-1);
        this.checkedIds.forEach( checkedItem => {
           if ( fileName === checkedItem ) {
             const data = {
               file: item.key,
               path: filePath,
               data: item.code
             }
             const isSuccess = window.ipcRenderer.sendSync('asynchronous-message',JSON.stringify(data));
             this.dialogVisible = !isSuccess;
             if (isSuccess) {
               Message.success("代码生成成功！")
             } else {
               Message.warning("代码生成失败！")
             }
           }
         })
      })
    },
    // 处理选中改变
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.previewCodes.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.previewCodes.length;
    }
  },
  created() {

  },
  // mounted生命周期
  mounted() {

  },
  // 生命周期updated执行时
  updated() {

  }
}
</script>

<style lang="less" scoped>
/* tree border */
.tree-border {
  margin-top: 5px;
  border: 1px solid #e5e6e7;
  background: #FFFFFF none;
  border-radius: 4px;
}
::v-deep .el-dialog__body {
  padding-bottom: 26px;
}
</style>
