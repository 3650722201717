/**
 * 数字软件商品表状态管理（vuex的state可以单独出来，便于管理，同样mutations和actions都可以提取出来）
 *
 * @author PeterLee
 * @since 2024-11-25
 */
const state = {
   //表单校验规则
   rules: {
      //软件名称
      name:[{
         required: true,
         message: '请输入软件名称',
         trigger: 'blur'
       },
         {
           min: 1,
           max: 20,
           message: '长度在 1 到 20 个字符',
           trigger: 'blur'
         }
       ],
      //版本号
      version:[{
         required: true,
         message: '请输入版本号',
         trigger: 'blur'
       },
         {
           min: 1,
           max: 20,
           message: '长度在 1 到 20 个字符',
           trigger: 'blur'
         }
       ],
      //文字简介
      shortDescription:[{
         required: true,
         message: '请输入文字简介',
         trigger: 'blur'
       },
         {
           min: 1,
           max: 20,
           message: '长度在 1 到 20 个字符',
           trigger: 'blur'
         }
       ],
      //超链简介
      longDescriptionLink:[{
         required: false,
         message: '请输入超链简介',
         trigger: 'blur'
       },
         {
           min: 1,
           max: 20,
           message: '长度在 1 到 20 个字符',
           trigger: 'blur'
         }
       ],
      //作者（昵称）
      authorNickname:[{
         required: true,
         message: '请输入作者（昵称）',
         trigger: 'blur'
       },
         {
           min: 1,
           max: 20,
           message: '长度在 1 到 20 个字符',
           trigger: 'blur'
         }
       ],
      //作者链接（网页地址，超链）
      authorLink:[{
         required: false,
         message: '请输入作者链接（网页地址，超链）',
         trigger: 'blur'
       },
         {
           min: 1,
           max: 20,
           message: '长度在 1 到 20 个字符',
           trigger: 'blur'
         }
       ],
      //下载链接
      downloadLink:[{
         required: true,
         message: '请输入下载链接',
         trigger: 'blur'
       },
         {
           min: 1,
           max: 20,
           message: '长度在 1 到 20 个字符',
           trigger: 'blur'
         }
       ],
      //状态 0：停用 1：启用
      state:[{
         required: true,
         message: '请选择状态 0：停用 1：启用',
         trigger: 'change'
       }],
   },
   //列信息
   columnInfo: {
       //软件名称
       name:{
            visible:true,
            label:"软件名称"
       },
       //版本号
       version:{
            visible:true,
            label:"版本号"
       },
       //文字简介
       shortDescription:{
            visible:true,
            label:"文字简介"
       },
       //超链简介
       longDescriptionLink:{
            visible:true,
            label:"超链简介"
       },
       //作者（昵称）
       authorNickname:{
            visible:true,
            label:"作者（昵称）"
       },
       //作者链接（网页地址，超链）
       authorLink:{
            visible:true,
            label:"作者链接（网页地址，超链）"
       },
       //下载链接
       downloadLink:{
            visible:true,
            label:"下载链接"
       },
       //状态 0：停用 1：启用
       state:{
            visible:true,
            label:"状态 0：停用 1：启用"
       },
   },
   //表格数据（查询结果）
   tableData: [],
   //共有数据条数（分页控件）
   totalCount: 0,
   //选项列表（列表下拉框选择）
   options: {
   },
   //查询参数
   queryParams: {
      //当前页数（分页控件）
      currPage: 1,
      //每页条数（分页控件）
      limit: 10,
      //是否升序
      ascOrder: false,
      //排序字段
      orderField: "",
      //此处为可能需要修改部分，请不要忽略
      param: {
         //软件名称
         name: undefined,
         //版本号
         version: undefined,
         //文字简介
         shortDescription: undefined,
         //超链简介
         longDescriptionLink: undefined,
         //作者（昵称）
         authorNickname: undefined,
         //作者链接（网页地址，超链）
         authorLink: undefined,
         //下载链接
         downloadLink: undefined,
         //状态 0：停用 1：启用
         state: undefined,
      }
   },
   //时间范围
   dateRange: {
   },
   //表单组件信息
   formInfo: {
      //表单组件弹出框显示控制
      dialogVisible: false,
      //是否增加操作（非增加操作会隐藏部分操作）
      isAdd: false,
      //编辑回显数据
      editData: {},
   },
   //更多按钮信息
   moreButtonProps:[
      //{command:'test',label:'示例按钮',permissionCode:'itil:event'}
   ]
}
export default state