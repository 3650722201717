<template>
    <div style="height: 100%;">
        <el-row :gutter="20" class="container">
                <!-- 右边主要区 -->
                <el-col :span="24" :xs="24" class="container" style="flex-direction: column;border-width: 0;padding-top: 25px;">
                    <!-- 右边头部搜索区 -->
                    <el-form :model="queryParams.param" ref="queryForm" :inline="true" label-position="right" v-show="showSearch" label-width="110px">
                                <!-- 软件名称 -->
                                <el-form-item :label="columnInfo.name.label" prop="name">
                                        <el-input v-model="queryParams.param.name" placeholder="请输入内容" size="small" clearable @keyup.enter.native="clickSearch"></el-input>
                                </el-form-item>
                                <!-- 版本号 -->
                                <el-form-item :label="columnInfo.version.label" prop="version">
                                        <el-input v-model="queryParams.param.version" placeholder="请输入内容" size="small" clearable @keyup.enter.native="clickSearch"></el-input>
                                </el-form-item>
                                <!-- 文字简介 -->
                                <el-form-item :label="columnInfo.shortDescription.label" prop="shortDescription">
                                        <el-input v-model="queryParams.param.shortDescription" placeholder="请输入内容" size="small" clearable @keyup.enter.native="clickSearch"></el-input>
                                </el-form-item>
                                <!-- 超链简介 -->
                                <el-form-item :label="columnInfo.longDescriptionLink.label" prop="longDescriptionLink">
                                        <el-input v-model="queryParams.param.longDescriptionLink" placeholder="请输入内容" size="small" clearable @keyup.enter.native="clickSearch"></el-input>
                                </el-form-item>
                                <!-- 作者（昵称） -->
                                <el-form-item :label="columnInfo.authorNickname.label" prop="authorNickname">
                                        <el-input v-model="queryParams.param.authorNickname" placeholder="请输入内容" size="small" clearable @keyup.enter.native="clickSearch"></el-input>
                                </el-form-item>
                                <!-- 作者链接（网页地址，超链） -->
                                <el-form-item :label="columnInfo.authorLink.label" prop="authorLink">
                                        <el-input v-model="queryParams.param.authorLink" placeholder="请输入内容" size="small" clearable @keyup.enter.native="clickSearch"></el-input>
                                </el-form-item>
                                <!-- 下载链接 -->
                                <el-form-item :label="columnInfo.downloadLink.label" prop="downloadLink">
                                        <el-input v-model="queryParams.param.downloadLink" placeholder="请输入内容" size="small" clearable @keyup.enter.native="clickSearch"></el-input>
                                </el-form-item>
                                <!-- 状态 0：停用 1：启用 -->
                                <el-form-item :label="columnInfo.state.label" prop="state">
                                        <enum-dict-select v-model="queryParams.param.state" module-name="system" dict-code="state"  placeholder="请选择" size="small" clearable/>
                                </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" size="small" @click="clickSearch">搜索</el-button>
                            <el-button icon="el-icon-refresh" size="small" @click="clickReset">重置</el-button>
                        </el-form-item>
                    </el-form>
                    <!-- 右边中部功能区 -->
                    <el-row :gutter="10" style="margin-bottom:13px;margin-right: 1px">
                        <el-col :span="1.5">
                            <el-button
                                    type="primary"
                                    plain
                                    icon="el-icon-plus"
                                    size="small"
                                    @click="clickAdd"
                                    v-permissionDisabled="'system:dataSoftwareProduct:create'"
                            >新增</el-button>
                        </el-col>
                        <el-col :span="1.5">
                            <excel-import-plus title="数字软件商品表" module-name="system" table-name="dataSoftwareProduct" :permission-code="'system:dataSoftwareProduct:import'"></excel-import-plus>
                        </el-col>
                        <el-col :span="1.5">
                            <excel-export-plus file-name="数字软件商品表" module-name="system" table-name="dataSoftwareProduct" :select-list="selectList" :permission-code="'system:dataSoftwareProduct:export'"></excel-export-plus>
                        </el-col>
                        <right-toolbar-plus :showSearch.sync="showSearch" @queryTable="handleTableSearch"
                                            :columnInfo="columnInfo" @redoTableLayout="redoTableLayout"></right-toolbar-plus>
                    </el-row>
                    <!-- 右边中部表格区 -->
                    <div class="main">
                        <div class="table">
                            <el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                                      @selection-change="handleSelectionChange" height="100%" :header-cell-style="{background:'#ECF5FF'}">
                                <el-table-column align="center" type="selection" width="55"/>
                                        <!-- 软件名称 -->
                                        <af-table-column align="center" prop="name" :label="columnInfo.name.label" v-if="columnInfo.name.visible">
                                        </af-table-column>
                                        <!-- 版本号 -->
                                        <af-table-column align="center" prop="version" :label="columnInfo.version.label" v-if="columnInfo.version.visible">
                                        </af-table-column>
                                        <!-- 文字简介 -->
                                        <af-table-column align="center" prop="shortDescription" :label="columnInfo.shortDescription.label" v-if="columnInfo.shortDescription.visible">
                                        </af-table-column>
                                        <!-- 超链简介 -->
                                        <af-table-column align="center" prop="longDescriptionLink" :label="columnInfo.longDescriptionLink.label" v-if="columnInfo.longDescriptionLink.visible">
                                        </af-table-column>
                                        <!-- 作者（昵称） -->
                                        <af-table-column align="center" prop="authorNickname" :label="columnInfo.authorNickname.label" v-if="columnInfo.authorNickname.visible">
                                        </af-table-column>
                                        <!-- 作者链接（网页地址，超链） -->
                                        <af-table-column align="center" prop="authorLink" :label="columnInfo.authorLink.label" v-if="columnInfo.authorLink.visible">
                                        </af-table-column>
                                        <!-- 下载链接 -->
                                        <af-table-column align="center" prop="downloadLink" :label="columnInfo.downloadLink.label" v-if="columnInfo.downloadLink.visible">
                                        </af-table-column>
                                        <!-- 状态 0：停用 1：启用 -->
                                        <af-table-column align="center" prop="state" :label="columnInfo.state.label" v-if="columnInfo.state.visible">
                                            <template v-slot="scope">
                                                <enum-dict-label v-model="scope.row.state" dict-code="state" module-name="system" show-tag></enum-dict-label>
                                            </template>
                                        </af-table-column>
                                <!-- 操作列 -->
                                <el-table-column align="center" label="操作" width="225" fixed="right">
                                    <template v-slot="scope">
                                        <el-button @click="clickEdit(scope.row)" type="primary" size="mini" v-permissionDisabled="'system:dataSoftwareProduct:update'">编辑</el-button>
                                        <el-button @click="clickDel(scope.row)" type="danger" size="mini" v-permissionDisabled="'system:dataSoftwareProduct:remove'">删除</el-button>
                                        <table-more-button :row="scope.row" :button-props="moreButtonProps" @handleCommand="handleCommand"></table-more-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <!-- 右边底部分页区 -->
                    <div style="height: 40px;margin-bottom: 0.1rem;">
                        <pagination-plus :totalCount="totalCount"
                                         :param="queryParams"
                                         :pageSizes="pageSizes"
                                         :selectList="selectList"
                                         module-name="system"
                                         table-name="dataSoftwareProduct"
                                         :del-all-permission-code="'system:dataSoftwareProduct:removes'"
                                         :state-permission-code="'system:dataSoftwareProduct:state'"
                                         @handleTableRefresh="handleTableSearch"/>
                    </div>
                </el-col>
        </el-row>
        <!-- 弹出层组件 -->
        <dataSoftwareProductForm/>
    </div>
</template>

<script>
    /**
     * 数字软件商品表主页面 （与自定义组件之间请遵循MVVM架构）
     * @author PeterLee
     * @since 2024-11-25
     */
    import dataSoftwareProductForm from "./DataSoftwareProductForm"
    import {mapState, mapMutations, mapActions} from "vuex";
    import {registerStore,unregisterStore} from "@/utils/common";
    import dataSoftwareProductDataProvider from "./DataSoftwareProductDataProvider";

    export default {
        components: {
            // 表单弹出组件
            dataSoftwareProductForm
        },
        data() {
            return {
                // 每页条数选项（分页控件）
                pageSizes: [10, 20, 40, 80],
                // 批量操作选中list（分页控件）
                selectList: [],
                // 展示搜索区（right-toolbar）
                showSearch: false,
                // 导出loading
                exportLoading: false,
            }
        },
        computed: {
            ...mapState({
                // 表格数据
                tableData: state => state.system.dataSoftwareProduct.tableData,
                // 下拉选项
                options: state => state.system.dataSoftwareProduct.options,
                // 共有数据条数（分页控件）
                totalCount: state => state.system.dataSoftwareProduct.totalCount,
                // 列信息
                columnInfo: state => state.system.dataSoftwareProduct.columnInfo,
                // 查询参数
                queryParams: state => state.system.dataSoftwareProduct.queryParams,
                // 时间范围
                dateRange: state => state.system.dataSoftwareProduct.dateRange,
                // 更多按钮信息
                moreButtonProps: state => state.system.dataSoftwareProduct.moreButtonProps,
            })
        },
        // 生命周期create函数
        created() {
            // 注册vuex模块
            registerStore('system','dataSoftwareProduct',dataSoftwareProductDataProvider);
            // 创建时获取所有权限数据（分页查询）
            this.handleTableSearch();
            // 获取OptionList
            this.getOptionList();
        },
        // 生命周期销毁函数
        destroyed() {
            // 解绑vuex模块
            unregisterStore('system','dataSoftwareProduct');
        },
        methods: {
            ...mapMutations({
                clickEdit: 'system/dataSoftwareProduct/clickEdit',
                clickAdd: 'system/dataSoftwareProduct/clickAdd',
            }),
            ...mapActions({
                clickDel: 'system/dataSoftwareProduct/handleDel',
                handleTableSearch: 'system/dataSoftwareProduct/handleTableSearch',
                getOptionList: 'system/dataSoftwareProduct/getOptionList',
            }),
            /** 重置按钮点击（搜索区） */
            clickReset() {
                for (let key of Object.keys(this.dateRange)) {
                    this.dateRange[key] = []
                }
                this.$refs['queryForm'].resetFields();
                this.handleTableSearch();
            },
            /** 搜索按钮点击（搜索区） */
            clickSearch() {
                this.queryParams.currPage = 1;
                this.handleTableSearch();
            },
            /** 处理选择改变（表格区） */
            handleSelectionChange(selectList) {
                this.selectList = selectList;
            },
            /** 重新计算表格（按钮区） */
            redoTableLayout() {
                // 数据加载完成后执行
                this.$nextTick(() => {
                    // 表格重新渲染
                    this.$refs.multipleTable.doLayout();
                });
            },
            /** 更多按钮方法处理（按钮区） */
            handleCommand(command,row) {
                // console.log(command)
                // console.log(row)
            }
        }
    }
</script>

<style lang="less" scoped>
    @import url('~@/assets/style/index.less');

    ::v-deep .el-form-item {
        margin-bottom: 18px;
    }
</style>
