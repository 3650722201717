<template>
    <div>
        <!-- 注：添加操作时，当左边没有树的值传入时，添加需要默认清空内容defaultEmpty，但包含树图或有内容传入时需要去掉该属性 -->
        <base-form-plus :isAdd="formInfo.isAdd" :relationDialogVisible.sync="formInfo.dialogVisible" showTitle="数字软件商品表"
                  :showData="formInfo.editData"
                  :rules="rules" @submitAdd="submitAdd" @submitEdit="submitEdit" ref="baseForm" defaultEmpty>
            <!-- 插槽部分 -->
            <template v-slot:default="{ruleForm}" class="info">
                        <!-- 软件名称 -->
                        <el-form-item :label="columnInfo.name.label" prop="name">
                                <el-input v-model="ruleForm.name" placeholder="请输入内容"  clearable></el-input>
                        </el-form-item>
                        <!-- 版本号 -->
                        <el-form-item :label="columnInfo.version.label" prop="version">
                                <el-input v-model="ruleForm.version" placeholder="请输入内容"  clearable></el-input>
                        </el-form-item>
                        <!-- 文字简介 -->
                        <el-form-item :label="columnInfo.shortDescription.label" prop="shortDescription">
                                <el-input v-model="ruleForm.shortDescription" placeholder="请输入内容"  clearable></el-input>
                        </el-form-item>
                        <!-- 超链简介 -->
                        <el-form-item :label="columnInfo.longDescriptionLink.label" prop="longDescriptionLink">
                                <el-input v-model="ruleForm.longDescriptionLink" placeholder="请输入内容"  clearable></el-input>
                        </el-form-item>
                        <!-- 作者（昵称） -->
                        <el-form-item :label="columnInfo.authorNickname.label" prop="authorNickname">
                                <el-input v-model="ruleForm.authorNickname" placeholder="请输入内容"  clearable></el-input>
                        </el-form-item>
                        <!-- 作者链接（网页地址，超链） -->
                        <el-form-item :label="columnInfo.authorLink.label" prop="authorLink">
                                <el-input v-model="ruleForm.authorLink" placeholder="请输入内容"  clearable></el-input>
                        </el-form-item>
                        <!-- 下载链接 -->
                        <el-form-item :label="columnInfo.downloadLink.label" prop="downloadLink">
                                <el-input v-model="ruleForm.downloadLink" placeholder="请输入内容"  clearable></el-input>
                        </el-form-item>
                        <!-- 状态 0：停用 1：启用 -->
                        <el-form-item :label="columnInfo.state.label" prop="state">
                                <enum-dict-select v-model="ruleForm.state" module-name="data" dict-code="state"></enum-dict-select>
                        </el-form-item>
            </template>
        </base-form-plus>
    </div>
</template>

<script>
    /**
     * 	数字软件商品表表单
     * 	@author PeterLee
     * 	@since 2024-12-03
     */
    import {mapState, mapMutations, mapActions} from "vuex";

    export default {
        name: 'softwareProductForm',
        // 计算操作
        computed: {
            ...mapState({
                // 校验规则
                rules: state => state.data.softwareProduct.rules,
                // 表格数据
                tableData: state => state.data.softwareProduct.tableData,
                // 表单信息
                formInfo: state => state.data.softwareProduct.formInfo,
                // 下拉选项
                options: state => state.data.softwareProduct.options,
                // 列信息
                columnInfo: state => state.data.softwareProduct.columnInfo,
            })
        },
        methods: {
            ...mapMutations({
                setDialogVisible: 'data/softwareProduct/setDialogVisible',
            }),
            ...mapActions({
                submitAdd: 'data/softwareProduct/submitAdd',
                submitEdit: 'data/softwareProduct/submitEdit',
            })
        }
    }
</script>

<style lang="less" scoped>
    .info {
        display: flex;
        flex-wrap: wrap;

        .el-form-item {
            width: 50%;
        }
    }
</style>
