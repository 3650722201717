<template>
  <el-container class="container">
    <el-main class="flex flex1 flex-wrap main">
      <div class="card flex flex-column">
        <div class="card_title">
          系统模块
        </div>
        <div class="">
          <div style="margin-left: 15px;margin-top: 5px;">加急开发中，敬请期待...</div>
<!--          11 模块管理页面，模块与用户绑定对应，用户和路径，用户名等一体-->
<!--          nacos-->
<!--          数据源（按钮绑定）模块绑定数据源，一对多-->
<!--          代码生成（按钮跳转到表）-->
<!--          自动部署（按钮）-->
<!--          自动测试（按钮）-->
<!--          模块配置（按钮）-->
        </div>
      </div>
    </el-main>
    <div style="width: 10px;"></div>
    <el-aside class="aside" width="372px">
      <div style="font-size: medium;font-weight: bolder;text-align: center;margin: 15px 10px 0;">智慧功能</div>
<!--      aside-->
<!--      用户信息-->
<!--      项目主要路径-->
<!--      登录名等-->
<!--      用户配置（按钮）-->
<!--      环境快速统一-->
<!--      基础包和模板管理-->
<!--      <el-button type="success" plain class="function_btn">智能建表</el-button>-->
      <AICreateTableButton class="function_btn" style="margin: 0; margin-top: 15px;">智能建表</AICreateTableButton>
      <el-button type="warning" plain class="function_btn">便捷开发</el-button>
      <el-button type="success" plain class="function_btn">人脸SDK集成项目下载</el-button>
    </el-aside>
  </el-container>
</template>

<script>
import AICreateTableButton from "@/views/data/workSpace/components/AICreateTableButton.vue";
export default {
  components: {
    AICreateTableButton
  },
  name: "WorkSpace"
  // 名称，版本号，文字简介（200字），超链简介，创建日期，作者（昵称），作者链接（网页地址，超链），下载链接（）
}
</script>

<style lang="less" scoped>
  .container {
    height: 100%;
    //border: solid #dcdfe6 1px;
    border-radius: 8px;
    .main {
      height: 100%;
      align-content: flex-start;
      overflow:auto;
      border: solid #dcdfe6 1px;
      border-radius: 8px;
      padding-left: 2px;
      padding-top: 15px;
      padding-bottom: 8px;
      background: #F1F5FB;
      .card {
        height: 230px;
        width: 18%;
        border: solid #dcdfe6 1px;
        margin-bottom: 8px;
        background: #FFFFFF;
        margin-left: 8px;
        border-radius: 8px;
        padding: 4px;
        .card_title {
          font-weight: bolder;
          font-size: medium;
          //text-align: center;
          margin: 10px 4px 4px 16px;
        }
      }
    }
    .aside {
      background: #F1F5FB;
      border-radius: 8px;
      border: solid #dcdfe6 1px;
      padding: 10px 10px 8px;
      .function_btn {
        width: 100%;
        margin-top: 15px;
        margin-left: 0;
      }
    }
  }
</style>