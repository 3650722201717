<template>
  <div class="digital-product-card">
    <div class="card-title">{{ product.name }}</div>
    <div class="card-version">版本号: {{ product.version }}</div>
    <div class="card-description">
      简介: {{ product.short_description }}
      <a :href="product.long_description_link" target="_blank" class="card-link">查看更多</a>
    </div>
    <div class="card-actions">
      <a :href="product.download_link" download class="card-download-link">下载软件</a>
      <!-- 可以添加购买按钮等其他操作 -->
    </div>
  </div>
</template>

<script>
// 数字商品卡片组件
export default {
  name: 'DigitalProductCard',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.digital-product-card {
  border: 1px solid #dcdfe6;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  background-color: #fff;
}

.card-title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 8px;
}

.card-version {
  font-size: 14px;
  margin-bottom: 16px;
}

.card-description {
  font-size: 14px;
  margin-bottom: 16px;
}

.card-link {
  color: #409eff;
  text-decoration: none;
}

.card-actions {
  display: flex;
  flex-direction: row;
}

.card-download-link {
  color: #409eff;
  text-decoration: none;
  margin-right: 16px; /* 根据需要调整间距 */
}
</style>
