<template>
  <div class="register-container">
    <vue-particles
        :color="'#AEDFFE'"
        :particleOpacity="0.7"
        :particlesNumber="80"
        shapeType="circle"
        :particleSize="4"
        linesColor="#dedede"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="3"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
        style="height: 100%;"
    />
    <div style="position: absolute;top:4rem;left: 6rem;">
      <div class="flex flex-ac">
        <div style="font-size: 42px;font-weight: bolder;color: #fff;">Redid</div>
        <div style="font-size: 32px;font-weight: bolder;color: #fff;margin-left: 0.2rem;">开放平台</div>
      </div>
      <div style="font-size: 24px;font-weight: bolder;color: #fff;">
        __助力数字技术革新,为产业赋能_
      </div>
    </div>
    <el-form :model="registerForm" :rules="rules" ref="registerForm" label-position="left" label-width="0px" class="register-page">
      <div class="title">注册redid开放平台</div>
      <el-form-item prop="username">
        <el-input placeholder="用户名" v-model="registerForm.username" prefix-icon="el-icon-user" @keyup.native.enter="handleSubmit"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input type="password" placeholder="密码" v-model="registerForm.password" show-password prefix-icon="el-icon-lock" @keyup.native.enter="handleSubmit"></el-input>
      </el-form-item>
      <el-form-item prop="confirmPassword">
        <el-input type="password" placeholder="确认密码" v-model="registerForm.confirmPassword" show-password prefix-icon="el-icon-lock" @keyup.native.enter="handleSubmit"></el-input>
      </el-form-item>
      <el-form-item  prop="email">
        <el-input placeholder="邮箱" v-model="registerForm.email" prefix-icon="el-icon-message" @keyup.native.enter="handleSubmit"></el-input>
      </el-form-item>
      <el-form-item prop="verificationCode">
         <div class="display-inline-block">
           <el-input style="width: 68%;margin-right: 16px;" placeholder="验证码" v-model="registerForm.verificationCode" prefix-icon="el-icon-ice-cream-square" @keyup.native.enter="handleSubmit"></el-input>
           <el-button type="text" @click="sendVerificationCode">{{sendText}}</el-button>
         </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" id="registerButton" style="width:100%;" :loading="loading" @click="handleSubmit">立即注册</el-button>
      </el-form-item>
      <router-link class="flex-ajc register" to="/userLogin">
        已有redid账户，前往登录>>
      </router-link>
    </el-form>
  </div>
</template>

<script>
import {getEmailCodeByRegister} from "@/api/systemApi";
import {validateLoginName, validateNumInteger} from "@/utils/validateUtil";
require('@/assets/geetest4')
export default {
  data() {
    return {
      loading: false,
      registerForm: {},
      captchaType: "getCode", // getCode和Register 滑块类型
      sendText: '发送验证码',
      rules: {
        username: [
          { validator: validateLoginName, required: true, trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        confirmPassword: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请再次输入密码'));
              } else if (value !== this.registerForm.password) {
                callback(new Error('两次输入密码不一致!'));
              } else {
                callback();
              }
            }, trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
        verificationCode: [{
          validator:validateNumInteger,
          required: true,
          message: '请输入正确的验证码',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted() {
    this.initGeeTest();
  },
  methods: {
    initGeeTest() {
      let _this = this;
      window.initGeetest4({
        captchaId: 'b6c16f2da2ee987963690692e8424dd3',
        product: 'bind',
        protocol: "https://"
      }, function (captcha) {
        // captcha为验证码实例
        window.captcha = captcha;
        captcha.onReady(function () {
          //your code
        }).onSuccess(function () {
          let result = window.captcha.getValidate();
          let data = _this.registerForm;
          data['lotNumber'] = result.lot_number;
          data['captchaOutput'] = result.captcha_output;
          data['passToken'] = result.pass_token;
          data['genTime'] = result.gen_time;
          _this.getEmailCodeByRegister(data)
        }).onError(function () {
          //your code
        }).onClose(function () {
          // 用户把验证关闭了，这时你可以提示用户需要把验证通过后才能进行后续流程
          // _this.loading = false;
        });
      });
    },
    // 调用后端API发送验证码到邮箱的逻辑
    sendVerificationCode() {
      // 这里只是一个示例，你需要根据实际情况来实现这部分逻辑
      if (this.registerForm.email) {
        // 校验通过，请求登录
        this.captchaType = 'getCode'
        window.captcha.showCaptcha();
      } else {
         this.$message.warning('请先输入邮箱地址！');
      }
    },
    // 获取邮箱验证码By注册
    getEmailCodeByRegister(data) {
      getEmailCodeByRegister(data).then(response => {
        if (response.code === 200) {
          // 处理发送成功的逻辑，比如显示一个提示信息给用户
          this.$message.success('验证码已发送到您的邮箱，请查收！');
          // 已发送
          // this.sendText = "已发送";
        }
      }).catch(() => {
        // 处理发送失败的逻辑，比如显示一个错误信息给用户
        this.$message.error('发送验证码失败，请稍后再试！');
      });
    },
    handleSubmit() {
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store.dispatch('user/register',this.registerForm).then(()=>{
            this.loading = false;
            this.registerForm.verificationCode="";
            // 休眠
            setTimeout(() => {
              // 跳转登录
              this.$router.push('/userLogin').then(()=>{})
            }, 1000);
          }).catch(()=> {
            this.loading = false;
            this.registerForm.verificationCode="";
          })
        } else {
          console.log('验证失败!');
          return false;
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
.register-page {
  position: absolute;
  top: 18%; /* 调整垂直位置 */
  left: 60%; /* 调整水平位置 */
  -webkit-border-radius: 10px;
  border-radius: 10px;
  width: 286px; /* 根据需要调整宽度 */
  padding: 35px 45px 20px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}

.register-container {
  width: 100%;
  height: 100%;
  background-image: url('~@/assets/overview_background.webp'); /* 背景图 */
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.title {
  text-align: center;
  font-weight: bolder;
  font-size: 17px;
  margin-bottom: 0.5rem;
  margin-top: 0.2rem;
  opacity: 0.88;
}

.register {
  color: #3a8ee6;
  margin-bottom: 20px;
  font-weight:bold;
  opacity: 0.6;
}
</style>