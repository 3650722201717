import { render, staticRenderFns } from "./DigitalProductCard.vue?vue&type=template&id=72a7a585&scoped=true"
import script from "./DigitalProductCard.vue?vue&type=script&lang=js"
export * from "./DigitalProductCard.vue?vue&type=script&lang=js"
import style0 from "./DigitalProductCard.vue?vue&type=style&index=0&id=72a7a585&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72a7a585",
  null
  
)

export default component.exports